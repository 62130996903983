import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const ContentWithMedia = loadable(() => import('/src/components/PageBuilder/layouts/standard/ContentWithMedia'))

const Complicated = ({ backgroundColour, utm }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            complicated {
                                fieldGroupName
                                complicated {
                                    addTitleArea
                                    title
                                    titlePosition
                                    description
                                    columnSizes
                                    mediaLocation
                                    mediaType
                                    image {
                                        id
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(width: 520)
                                            }
                                        }
                                        altText
                                        title
                                    }
                                    video
                                    content
                                    addCta
                                    ctaLocation
                                    callToAction {
                                        target
                                        title
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        let componentData = node.globalBlocks.complicated.complicated

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: componentData.titlePosition,
            title: componentData.title,
            description: componentData.description
        }

        return (
            <ContentWithMedia
                key={index}
                backgroundColour={backgroundColour}
                titleArea={titleArea}
                columnSizes={componentData.columnSizes}
                mediaLocation={componentData.mediaLocation}
                mediaType={componentData.mediaType}
                image={componentData.image}
                video={componentData.video}
                content={componentData.content}
                addCta={componentData.addCta}
                ctaLocation={componentData.ctaLocation}
                callToAction={componentData.callToAction}
                className={'c-complicated'}
                utm={utm}
            />
        )
    })
}

Complicated.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

Complicated.defaultProps = {
    backgroundColour: 'dark'
}

export default Complicated

export const query = graphql`
    fragment ComplicatedForPageBuilder on WpPage_Acfpagebuilder_Layouts_Complicated {
        fieldGroupName
        backgroundColour
    }
`
